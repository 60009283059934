import {getKeyVal, localData} from "@/lib/data";

function checkLogin() {
    let token = getKeyVal(Token(), 'token', '');
    return token && token.length > 0;
}

function Token(val: any = undefined) {
    if (val === undefined) {
        let token = localData('AccessToken');
        return token ? token : null;
    } else {
        localData('AccessToken', val);
        return null;
    }
}

export {
    checkLogin,
    Token
}
