import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';
import {checkLogin} from "@/lib/access";

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/WebLayout.vue'),
        children: [
            {
                path: '',
                name: 'webIndex',
                component: () => import('@/views/Web/Index.vue'),
            },
            {
                path: 'tool',
                name: 'webTool',
                component: () => import('@/views/Web/Tool.vue'),
            },
            {
                path: 'contact',
                name: 'webContact',
                component: () => import('@/views/Web/Contact.vue'),
            },
            {
                path: 'car-data',
                name: 'webCarData',
                component: () => import('@/views/Web/CarData.vue'),
            },
            {
                path: 'car',
                name: 'webCar',
                component: () => import('@/views/Web/Car.vue'),
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/views/Web/About.vue'),
            },
            {
                path: 'case',
                name: 'case',
                component: () => import('@/views/Web/Case.vue'),
            },
            {
                path: 'case/detail',
                name: 'caseDetail',
                component: () => import('@/views/Web/CaseDetail.vue'),
            },
            {
                path: 'car-search',
                name: 'webCarSearch',
                component: () => import('@/views/Web/CarSearch.vue'),
            },
            {
                path: 'pay/result',
                name: 'payResult',
                component: () => import('@/views/Web/PayResult.vue'),
            }
        ]
    },
    {
        path: '/uCenter',
        component: () => import('@/components/layout/ControlLayout.vue'),
        children: [
            {
                path: '',
                name: 'index',
                component: () => import('@/views/IndexView.vue'),
            },
            {
                path: 'buyCredits',
                name: 'buyCredits',
                component: () => import('@/views/BuyCredits.vue'),
            },
            {
                path: 'orderHistory',
                name: 'orderHistory',
                component: () => import('@/views/OrderHistory.vue'),
            },
            {
                path: 'creditTransactions',
                name: 'creditTransactions',
                component: () => import('@/views/CreditTransactions.vue'),
            },
            {
                path: 'newFileService',
                name: 'newFileService',
                component: () => import('@/views/NewFileService.vue'),
            },
            {
                path: 'fileServices',
                name: 'fileServices',
                component: () => import('@/views/FileServices.vue'),
            },
            {
                path: 'fileDynographs',
                name: 'fileDynographs',
                component: () => import('@/views/FileDynographs.vue'),
            },
            {
                path: 'supportTickets',
                name: 'supportTickets',
                component: () => import('@/views/SupportTickets.vue'),
            },
            {
                path: 'supportTicketDetail',
                name: 'supportTicketDetail',
                component: () => import('@/views/SupportTicketDetail.vue'),
            },
            {
                path: 'openSupportTicket',
                name: 'openSupportTicket',
                component: () => import('@/views/OpenSupportTicket.vue'),
            },
            {
                path: 'changePassword',
                name: 'changePassword',
                component: () => import('@/views/ChangePassword.vue'),
            },
            {
                path: 'info',
                name: 'info',
                component: () => import('@/views/Info.vue'),
            }
        ]
    },
    {
        path: '/access',
        component: () => import('@/components/layout/DefaultLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/LoginView.vue'),
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('@/views/RegisterView.vue'),
            },
            {
                path: 'forgot',
                name: 'forgotPassword',
                component: () => import('@/views/ForgotPassword.vue'),
            },
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.7)',
    });
    if(to.name == 'webIndex' || to.name == 'webTool' || to.name == 'webContact' ||
        to.name == 'webCarData' || to.name == 'webCar' || to.name == 'webCarSearch' || to.name == 'payResult' || to.name == 'about' || to.name == 'case' ||to.name == 'caseDetail') {
        next();
    } else if (to.name != 'login' && to.name != 'register' && to.name != 'forgotPassword' && !checkLogin()) {
        next({name: 'login', query: {redirect: encodeURIComponent(from.fullPath)}});
    } else if ((to.name == 'login' || to.name == 'register' || to.name == 'forgotPassword') && checkLogin()) {
        next({name: 'index'});
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
})
export default router
